import React, { useEffect } from 'react';
import queryString from 'query-string';
import MetaTags from 'react-meta-tags';
import SectionOne from '../../pagePartials/custom/SectionOne';
import SectionTwo from '../../pagePartials/custom/SectionTwo';
import SectionTwocows from '../../pagePartials/custom/SectionTwocows';
import SectionThree from '../../pagePartials/custom/SectionThree';
import SectionFour from '../../pagePartials/custom/SectionFour';
import SectionFive from '../../pagePartials/custom/SectionFive';
import SectionSix from '../../pagePartials/custom/SectionSix';
import { whichCompany } from '../../utils/pageUtils';
import history from 'history';

const CustomLandingPage = () => {
  const company = whichCompany();
  const {
    path,
    logo,
    secondLogo,
    title,
    minutes,
    singleParagraph,
    singleParagraphFr,
    EFAP,
    isDMZ,
    subsequentSessions,
    subsequentSessionsFr,
    subsequentSessionsUS,
    frLogo,
    frSecondLogo,
    frTitle,
    removeLegalFinancial,
    removeSecondParagraph,
    finalParagraph,
    finalParagraphFr,
    heures,
    EAP,
    efapSecond,
    efapSecondFr,
    efapThird,
    efapThirdFr,
  } = company;
  if (window) {
    const query = queryString.parse(window.location.search);
    if (
      query &&
      query.locale === 'fr' &&
      localStorage.getItem('selectedLanguage') !== 'fr'
    ) {
      localStorage.setItem('selectedLanguage', 'fr');
      window.location.reload();
    } else if (!query) {
      localStorage.setItem('selectedLanguage', 'en');
    }
  }

  useEffect(() => {
    if(company.path === 'roomforher') {
      window.location = 'https://www.greenshield.ca/en-ca/cares/womens-mental-health'
    }
  }, [])

  return (
    <div className="custom-index-page">
      <MetaTags>
        <meta name="robots" content="noindex" />
      </MetaTags>
      <SectionOne path={path} EFAP={EFAP} EAP={EAP} />
      <SectionTwo
        logo={logo}
        secondLogo={secondLogo}
        title={title}
        EFAP={EFAP}
        EAP={EAP}
        minutes={minutes}
        singleParagraph={singleParagraph}
        singleParagraphFr={singleParagraphFr}
        subsequentSessions={subsequentSessions}
        subsequentSessionsFr={subsequentSessionsFr}
        subsequentSessionsUS={subsequentSessionsUS}
        frLogo={frLogo}
        frSecondLogo={frSecondLogo}
        frTitle={frTitle}
        removeSecondParagraph={removeSecondParagraph}
        finalParagraph={finalParagraph}
        finalParagraphFr={finalParagraphFr}
        heures={heures}
        efapSecond={efapSecond}
        efapSecondFr={efapSecondFr}
        efapThird={efapThird}
        efapThirdFr={efapThirdFr}
      />
      {// This section details the Tucows partnership with Lifespeak
      title === 'Tucows' && <SectionTwocows />}
      <SectionThree title={title} />
      <SectionFour
        EFAP={EFAP}
        removeLegalFinancial={removeLegalFinancial}
        EAP={EAP}
        path={path}
      />
      <SectionFive />
      <SectionSix path={path} isDMZ={isDMZ} />
    </div>
  );
};

export default CustomLandingPage;
