import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import { parseCompanyCodeFromPath } from '../../utils/pageUtils';
import ErrorText from '../ErrorText';
import CompanySearch from './CompanySearch';
import FullyGated from './FullyGated';
import Dependants from './Dependants';
import EmployeeIdSignup from '../custom/EmployeeIdSignup';

function ASEBPSignUp({ planType, companyName }) {
  const [company, setCompany] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [hideImage, setHideImage] = useState(false);
  const [showDependants, setShowDependants] = useState(false);
  const path = parseCompanyCodeFromPath();

  useEffect(() => {
    clearError();
  }, [company]);

  function clearError() {
    setErrorMessage('');
  }

  function setError(error) {
    const fallbackError = <FormattedMessage id="error.fallbackMessage" />;
    const serverError = get(error, 'response.data.error', fallbackError);

    setErrorMessage(serverError);
  }

  function goBack() {
    setCompany(null);
    setShowDependants(false);
  }

  function renderSignUp() {
    const companySearch = (
      <CompanySearch
        planType={planType}
        setCompany={setCompany}
        setError={setError}
        companyName={companyName}
      />
    );

    if (company === null) {
      return companySearch;
    }

    const gatedProps = {
      clearError,
      companyId: company.value,
      companyName: company.label,
      goBack,
      path,
      setError,
      setHideImage
    };
    if (company && showDependants) {
      return <Dependants {...gatedProps} />;
    }

    switch (company.portal_gating) {
      case 'full':
        return <FullyGated {...gatedProps} employeeId={company.employee_id} />;
      default:
        return companySearch;
    }
  }

  function renderLinks() {
    return (
      <div>
        {company && company.dependants && !showDependants && (
          <h4>
            <FormattedMessage id="eap.spouseOrDependant" />{' '}
            <span
              onClick={() => setShowDependants(true)}
              role="presentation"
              style={{ color: '#2a5ccd', cursor: 'pointer' }}
            >
              <FormattedMessage id="eap.registerHere" />
            </span>
          </h4>
        )}
        <h4>
          <FormattedMessage id="eap.alreadyHave" />
          <Link to={`/sign_in?company=${path}`} style={{ color: '#2a5ccd' }}>
            <FormattedMessage id="eap.signin" />
          </Link>
        </h4>
      </div>
    );
  }

  function renderTitle() {
    let title;
    if (showDependants) {
      title = <FormattedMessage id="eap.dependantAccountCreate" />;
    } else {
      title = <FormattedMessage id="eap.gettingStarted" />;
    }
    return <h2>{title}</h2>;
  }
  return (
    <>
      {company?.employee_id ? (
        <EmployeeIdSignup companyID={company.value} childCompany={company} />
      ) : (
        <div className="pink-background">
          <div className="sign-up-container">
            <div className="sign-up-logo">
              <Link to={`/${path}`}>
                <img src={InkblotLogo} alt="inkblot logo" />
              </Link>
            </div>
            {!hideImage && (
              <img className="sign-up-image" src={SessionTwo} alt="" />
            )}
            <div style={{ width: '100%' }}>
              {renderTitle()}
              {renderSignUp()}
              <div style={{ margin: '20px 0' }}>
                <ErrorText>{errorMessage}</ErrorText>
              </div>
              {renderLinks()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ASEBPSignUp.propTypes = {
  planType: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  path: PropTypes.string
};

export default ASEBPSignUp;
