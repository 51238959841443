import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import _ from 'lodash';
import Layout from '../components/layout';

import CustomLandingPage from '../components/custom';
import CorporateNavPage from '../pagePartials/corporate-nav-page';

import SignIn from '../components/custom/SignIn';
import Dependants from '../components/custom/Dependants';

import {
  whichCompany,
  isCorporateLanding,
  isGallivanPage,
  isMLF,
  isLeslie,
  isTrustBasedWithEFSignup,
  isTrustBasedNoEFSignup,
  isFullyGatedSignUp,
  isDomainGatedSignUp,
  isOmers,
  isCFS,
  isILOB,
  isNSHN,
  isPPCHealthAkira,
  isPPCHealthTelus,
  isPCMCHCHealthTelushealth,
  isPPCHealthMaple,
  isCSA,
  isPPCHealthLifeInc,
  isPPCHealthBCI,
  isATB,
  isCorporateNav,
  isClientGscAndSetLocale,
} from '../utils/pageUtils';
import DMZSignup from '../components/custom/DMZSignup';
import WellSignUp from '../components/custom/WellSignup';
import EsgSignUp from '../components/custom/EsgSignUp';
import TrustBasedWithEFSignup from '../components/custom/TrustBasedWithEFSignup';
import TrustBasedNoEFSignup from '../components/custom/TrustBasedNoEFSignUp';
import FullyGatedSignUp from '../components/custom/FullyGatedSignUp';
import DomainGatedSignUp from '../components/custom/DomainGatedSignUp';
import ATBSignUp from '../components/custom/ATBSignup';
import ATBSignIn from '../components/custom/ATBSignin';
import EmployeeIdSignUp from '../components/custom/EmployeeIdSignup';

import GallivanIndex from '../components/gallivan';
import GallivanAbout from '../components/gallivan/About';
import GallivanFaq from '../components/gallivan/Faq';
import schools from '../pagePartials/gallivan/schools';

import LeslieIndex from '../components/Leslie';
import OmersIndex from '../components/Omers';
import CFSIndex from '../components/CFS';
import ILOBIndex from '../components/ILOB';
import ResetPassword from '../components/reset_password';
import Unsubscribe from './unsubscribe';
import IndigoSignup from '../components/custom/IndigoSignup';
import EligibilityWelcome from '../components/eligibility';

import MLF from '../components/mlf';
import MLFSignup from '../components/custom/MLFSignup';
import TelusHealth from '../components/people_connect_total_health/telus_health';
import Maple from '../components/people_connect_total_health/maple';
import CSA from '../components/people_connect_total_health/csa';
import LifeInc from '../components/people_connect_total_health/lifeinc';
import BCI from '../components/people_connect_total_health/bci';
import ATB from '../components/people_connect_total_health/atb';
import Subprocessors from './subprocessors';
import MobileApp from './mobile_app';
import NotFoundPage from './404';
import GscSignUp from '../components/GscSignUp';
import { redirectTo } from '../utils/redirect';
import ASEBPSignUp from '../components/ASEBPSignUp';
import { Redirect, Route } from 'react-router-dom';

const App = props => (
  <Layout location={props.location}>
    <Router>
      {isCorporateLanding(props.location.pathname) && (
        <CustomLandingPage path={`/${props.location.pathname}`} />
      )}
      {isCorporateNav(props.location.pathname) && (
        <CorporateNavPage path={`/${props.location.pathname}`} />
      )}

      {whichCompany().path === 'indigo' && <IndigoSignup path="/sign_up" />}
      {whichCompany().path === 'dmz' && <DMZSignup path="/sign_up" />}
      {whichCompany().path === 'thesweetpotato' && (
        <DMZSignup path="/sign_up" />
      )}
      {whichCompany().path === 'mapleleaffoods' && (
        <MLFSignup path="/sign_up" />
      )}
      {whichCompany().path === 'cbn' && <DMZSignup path="/sign_up" />}
      {whichCompany().path === 'eastcoaststrong' && (
        <DMZSignup path="/sign_up" />
      )}
      {whichCompany().path === 'esg' && <EsgSignUp path="/sign_up" />}
      {whichCompany().path === 'well' && <WellSignUp path="/sign_up" />}
      {whichCompany().path === 'atb' && <ATBSignUp path="/sign_up" />}
      {whichCompany().path === 'atb' && <ATBSignIn path="/sign_in" />}
      {whichCompany().path === 'asebp' && (
        <ASEBPSignUp companyName="asebp" path="/sign_up" planType="eap" />
      )}
      {whichCompany().path === 'benecaid-eap' && (
        <GscSignUp companyName="benecaid" path="/sign_up" planType="eap" />
      )}
      {whichCompany().path === 'honeybee-eap' && (
        <GscSignUp companyName="honeybee" path="/sign_up" planType="eap" />
      )}
      {whichCompany().path === 'gsc-eap' && (
        <GscSignUp companyName="gsc" path="/sign_up" planType="eap" />
      )}
      {whichCompany().path === 'maple-eap-uv' && (
        <GscSignUp companyName='maple_uv' path='/sign_up' planType='eap' />
      )}
      {whichCompany().path === 'maple-eap' && (
        <GscSignUp companyName="maple" path="/sign_up" planType="eap" />
      )}
      {whichCompany().path === 'gsc-access' && (
        <GscSignUp companyName="gsc" path="/sign_up" planType="access" />
      )}
      {whichCompany().path ==='firstcanadian' && (
        <GscSignUp companyName='firstcanadian' path="/sign_up" planType="eap"/>
      )}
      {whichCompany().path === 'inconfidence' && (
        <GscSignUp companyName='inconfidence' path="/sign_up" planType='eap'/>
      )}
      {whichCompany().path === 'inconfidencepersonal' && (
        <GscSignUp companyName='inconfidence_personal' path="/sign_up" planType='eap'/>
      )}
      {whichCompany().employeeIdSignup && <EmployeeIdSignUp path="/sign_up" />}
      {whichCompany().employeeIdSignup && <ATBSignIn path="/sign_in" />}

      {isTrustBasedWithEFSignup(props.location.pathname) && (
        <TrustBasedWithEFSignup path="/sign_up" />
      )}
      {isTrustBasedNoEFSignup(props.location.pathname) && (
        <TrustBasedNoEFSignup path="/sign_up" />
      )}
      {isFullyGatedSignUp(props.location.pathname) && (
        <FullyGatedSignUp path="/sign_up" />
      )}
      {isDomainGatedSignUp(props.location.pathname) && (
        <DomainGatedSignUp path="/sign_up" />
      )}
      {isGallivanPage(props.location.pathname) &&
        _.map(schools, school => (
          <div key={school.key} path={props.location.pathname}>
            <GallivanIndex key={school.key} path="/" />
            <GallivanAbout key={school.key} path="/about" />
            <GallivanFaq key={school.key} path="/faq" />
          </div>
        ))}
      {isMLF(props.location.pathname) && <MLF path="/mlf" />}
      {isPPCHealthTelus(props.location.pathname) && (
        <TelusHealth path="/ppc_health_telushealth" />
      )}
      {isPPCHealthAkira(props.location.pathname) && (
        <TelusHealth path="/ppc_health_akira" />
      )}
      {isPCMCHCHealthTelushealth(props.location.pathname) && (
        <TelusHealth path="/PCMCHC_health_telushealth" />
      )}
      {isPPCHealthMaple(props.location.pathname) && (
        <Maple path="/ppc_health_maple" />
      )}
      {isPPCHealthLifeInc(props.location.pathname) && (
        <LifeInc path="/ppc_health_life_inc" />
      )}
      {isPPCHealthBCI(props.location.pathname) && (
        <BCI path="/ppc_health_bci" />
      )}
      {isCSA(props.location.pathname) && <CSA path="/csa_land" />}
      {isATB(props.location.pathname) && <ATB path="/atb_land" />}
      {isLeslie(props.location.pathname) && <LeslieIndex path="/care" />}
      {isOmers(props.location.pathname) && <OmersIndex path="/omers" />}
      {isCFS(props.location.pathname) && (
        <CFSIndex path={`/${props.location.pathname}`} />
      )}
      {isILOB(props.location.pathname) && <ILOBIndex path="/gsc-customer" />}
      {isClientGscAndSetLocale(props.location.pathname) &&
        redirectTo('/gsc-customer')}
      {isNSHN(props.location.pathname) && <CFSIndex path="/nshn" />}
      <Route exact path={'/roomforher'} >
        <Redirect to='https://www.greenshield.ca/en-ca/cares/womens-mental-health'/>
      </Route>

      <ResetPassword path="/reset_password/:reset_password_token" />
      <EligibilityWelcome path="/onboarding/:eligibility_token" />
      <FullyGatedSignUp path="/sign_up" />
      <SignIn path="/sign_in" />
      <Dependants path="/dependants" />
      <Unsubscribe path="/unsubscribe" />
      <Subprocessors path="/subprocessors" />
      <MobileApp path="/mobileapp" />
      <NotFoundPage path="*" />
    </Router>
  </Layout>
);

App.propTypes = {
  location: PropTypes.object.isRequired,
};

export default App;
