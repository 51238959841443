import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { whichCompany } from '../../utils/pageUtils';
import SectionContainer from '../../components/SectionContainer';
import teamworkWhite12 from '../../images/custom/illustrations/teamwork-white-12.svg';
import crisisLine from '../../images/custom/illustrations/crisis-line.svg';
import { Link } from 'gatsby';
import { COUNTRY } from '../../utils/environment';

const SectionOne = ({ EFAP, intl, EAP }) => {
  function renderHeader() {
    if (whichCompany().path === 'endy') {
      return intl.formatMessage({ id: 'eap.takeCareEnhanced' });
    }
    if (whichCompany().path === 'feminuity'){
      return intl.formatMessage({ id: 'eap.takeCareTMAP' });
    }
    if(whichCompany().path === 'eiqc'){
      return intl.formatMessage({id: 'eap.takeCareEIQC'});
    }
    if(whichCompany().path === 'inconfidence' || whichCompany().path === 'inconfidencepersonal'){
      return <FormattedMessage
        id={'eap.takeCareInConfidence'}
        defaultMessage={"To create your account, click Get Started. Your “Organization code” is part the Medavie policy number on your Member card or mobile app. Enter the 5 digits starting with the 3rd. (e.g. 00{b}000)."}
        values={{b:<span style={{color: 'red'}}>12345</span>}}
      />
    }
    if (EAP) {
      return intl.formatMessage(
        {
          id: 'eap.takeCareEap',
          defaultMessage:
            'Take care of it with your confidential Employee Assistance Program (EAP).',
        },
        {
          employeeEN: whichCompany().path === 'arta' || whichCompany().path === 'pas' || whichCompany().path === 'cima' ? 'Member' : 'Employee',
          eapEN: whichCompany().path === 'arta' || whichCompany().path === 'pas' || whichCompany().path === 'cima' ? 'MAP' : 'EAP',
          employeeFR: whichCompany().path === 'arta' || whichCompany().path === 'pas' || whichCompany().path === 'cima' ? 'Membres' : 'Employés',
          eapFR: whichCompany().path === 'arta' || whichCompany().path === 'pas' || whichCompany().path === 'cima' ? 'PAM' : 'PAE',
        },
      );
    }
    if (EFAP) {
      if (whichCompany().path === 'asebp') {
        return intl.formatMessage({ id: 'eap.yourTakeCare' });
      }
      return intl.formatMessage({
        id: 'eap.takeCare',
        defaultMessage:
          'Take care of it with your confidential Employee and Family Assistance Program (EFAP).',
      });
    }
  }

  const renderPreHeader = () => {

    if(whichCompany().path === 'inconfidence' || whichCompany().path === 'inconfidencepersonal') return (
        <span className="dark-blue">
          {intl.formatMessage({
            id: 'eap.yourWellBeingInConfidence',
            defaultMessage: 'inConfidence powered by Inkblot',
          })}
        </span>
    )

    return (
        <span className="dark-blue">
          {intl.formatMessage({
            id: 'eap.yourWellBeing',
            defaultMessage: 'Your well-being matters.',
          })}
        </span>
    )
  }

  return (
    <SectionContainer container num={1}>
      <div className="row">
        <div className="col-6">
          <div className="header">
            <div className={EFAP || EAP ? 'EFAP-hidden' : ''}>
              <p className="custom-header">
                <span className="dark-blue">
                  {whichCompany().path === 'eastcoaststrong'
                    ? intl.formatMessage({
                        id: 'eap.sincereCondolences',
                        defaultMessage:
                          'Our sincere condolences to all of those affected by the recent tragedy in Nova Scotia.',
                      })
                    : whichCompany().path === 'roomforher'
                    ? intl.formatMessage({
                        id: 'eap.itsTime',
                        defaultMessage:
                          'It’s time to make room for women who need accessible mental health solutions.',
                      })
                    : intl.formatMessage({
                        id: 'eap.yourMentalHealth',
                        defaultMessage:
                          'Your mental health is as important as your physical health.',
                      })}
                </span>{' '}
                {whichCompany().path === 'eastcoaststrong'
                  ? intl.formatMessage({
                      id: 'eap.weStandBy',
                      defaultMessage:
                        'We stand by you in this extremely difficult time.',
                    })
                  : whichCompany().path === 'roomforher'
                  ? intl.formatMessage({
                      id: 'eap.takeCareRFH',
                      defaultMessage:
                        'Start your journey to feeling better with Inkblot’s confidential video counselling.',
                    })
                  : intl.formatMessage({
                      id: 'eap.takeCareWith',
                      defaultMessage:
                        'Take care of it with Inkblot’s confidential video counselling.',
                    })}
              </p>
            </div>
            <div className={EFAP || EAP ? '' : 'EFAP-hidden'}>
              <p className='custom-header'>{renderPreHeader()}</p>
              <p className="custom-header">{renderHeader()}</p>
            </div>
          </div>
        </div>
        {}
        <div
          className="col-6 custom-svg"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <img src={teamworkWhite12} alt="" />
          {(EFAP || EAP) && (
            <div className="crisis-line">
              <img src={crisisLine} alt="Orange alert" />
              <FormattedHTMLMessage
                tagName="span"
                defaultMessage="If you are in crisis, contact: <b>1-855-933-0103</b> (toll-free, 24/7/365)"
                id="eap.crisisText"
              />
            </div>
          )}
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem'}}>
        <Link to={whichCompany().registrationFlow === 'redesign_2023' ? 
          `https://registration.inkblottherapy.com?${COUNTRY === 'CA' ? 'country=ca' : 'country=us'}&b2b=true${intl.locale === 'fr' ? '&locale=fr' : ''}` : 
          `/sign_up?company=${whichCompany().path}`}
        >
          <div
            className="custom-button-blue start-btn"
            style={{ margin: 'auto', cursor: 'pointer'}}
          >
              {intl.formatMessage({
                  id: "navigation.createMyAccount",
                  defaultMessage: "Get Started",
              })}
          </div>
        </Link>
      </div>
    </SectionContainer>
  );
};

SectionOne.propTypes = {
  EFAP: PropTypes.bool,
  intl: PropTypes.object,
  EAP: PropTypes.bool,
};

SectionOne.defaultProps = {
  intl: {},
  EFAP: false,
  EAP: false,
};

export default injectIntl(SectionOne);
