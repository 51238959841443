import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'gatsby';
import teamworkWhite9 from '../../images/custom/illustrations/teamwork-white-9.svg';
import { whichCompany } from '../../utils/pageUtils';
import { COUNTRY } from '../../utils/environment';

const SectionSix = props => {
  const { path, intl, isDMZ } = props;
  return (
    <div className="section-6">
      <img src={teamworkWhite9} alt="" />
      <div>
        <h2>
          {whichCompany().path === 'roomforher'
            ? intl.formatMessage({
                id: 'eap.feelBetterRFH',
                defaultMessage: 'FeelBetter, ',
              })
            : intl.formatMessage({
                id: 'eap.feelBetter',
                defaultMessage: 'FeelBetter, ',
              })}
          <span>
            {intl.formatMessage({
              id: 'eap.liveBetter',
              defaultMessage: 'Live Better,',
            })}
          </span>
        </h2>
        <p>
          {whichCompany().bestWay &&
            (intl.locale === 'fr'
              ? whichCompany().bestWayFr
              : whichCompany().bestWay)}

          {!whichCompany().bestWay &&
            (whichCompany().path === 'roomforher'
              ? intl.formatMessage({
                  id: 'eap.bestWayRFH',
                  defaultMessage:
                    'Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships.',
                })
              : intl.formatMessage({
                  id: 'eap.bestWay',
                  defaultMessage:
                    'Counselling is the best way to achieve mental well-being, learn to cope with work and life stressors and improve your relationships.',
                }))}
        </p>
        <h4>
          {whichCompany().path === 'roomforher' ? (
            <FormattedHTMLMessage
              tagName="p"
              id="eap.improveMental"
              defaultMessage="Improve your mental health at home, at work and beyond with <b>effective</b> and <b>affordable</b> solutions built for you."
            />
          ) : (
            <FormattedMessage
              id="eap.bookSession"
              defaultMessage="Book a session"
            />
          )}
        </h4>
        <Link
          title="Get Started"
          to= 
          {isDMZ
            ? '/sign_up?company=dmz'
            : whichCompany().registrationFlow === 'redesign_2023'
                ? `https://registration.inkblottherapy.com?${COUNTRY === 'CA'
                  ? 'country=ca'
                  : 'country=us'
                }&b2b=true${intl.locale === 'fr'
                  ? '&locale=fr'
                  : ''
                }`                  
            : `/sign_up?company=${whichCompany().path}` 
          }
          className="custom-button-blue"
        >
          {whichCompany().path === 'roomforher' ? (
            <FormattedMessage
              id="eap.bookSession"
              defaultMessage="Book a session"
              style={{ padding: 0 }}
            />
          ) : (
            <FormattedMessage
              id="eap.getStarted"
              defaultMessage="Get Started"
              style={{ padding: 0 }}
            />
          )}
        </Link>
      </div>
    </div>
  );
};

SectionSix.propTypes = {
  path: PropTypes.string.isRequired,
  intl: PropTypes.object,
  isDMZ: PropTypes.bool,
};

SectionSix.defaultProps = {
  intl: {},
  isDMZ: false,
};

export default injectIntl(SectionSix);
