import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link, navigate } from 'gatsby';
import _ from 'lodash';

import SessionTwo from '../../images/custom/illustrations/session-2.svg';
import InkblotLogo from '../../images/nav/inkblot-logo.svg';
import {
  SERVER_URL,
  THERAPY_URL,
  OTHER_SERVER_URL,
  OTHER_THERAPY_URL,
} from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import {
  parseCompanyCodeFromPath,
  unauthAuditProgress,
} from '../../utils/pageUtils';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_SIGNIN,
  COMPLETED_SIGNIN,
  logEvent,
  setUserId,
} from '../../utils/amplitude';
import SpinnerButton from '../SpinnerButton';
import EyeIcon from '../EyeIcon';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      visible: false,
    };

    this.companyCode = parseCompanyCodeFromPath();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.clearError, false);
    unauthAuditProgress(this.companyCode, 'open_signin');
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.clearError, false);
  }

  clearError = () => {
    this.setState({ error: null });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();
  };

  redirect = (response, url, email) => {
    const twoFactorId = response.data.two_factor_id;
    const userId = response.data.user_id;

    setUserId(userId);
    logEvent(COMPLETED_SIGNIN, { page: this.companyCode });

    if (twoFactorId) {
      navigate('/two_factor', { state: { email, twoFactorId } });
    } else {
      const token = response.data.access_token;

      redirectToTherapy(token, null, url);

      this.setState({ loading: false });
    }
  };

  // copied from src/components/auth/SignIn.js
  signIn = () => {
    this.setState({ loading: true, error: null });
    const values = _.omit(this.state, ['error']);

    const currentLocale = localStorage.getItem('selectedLanguage');

    axios
      .post(`${SERVER_URL}/api/login`, {
        ...values,
        source: 'therapy',
        locale: currentLocale,
      })
      .then(mainResponse =>
        // success in DB
        this.redirect(mainResponse, THERAPY_URL, values.email))
      .catch(mainError => {
        if (mainError.response.status === 422) {
          // no exist in DB, check other country DB
          axios
            .get(
              `${OTHER_SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
                values.email,
              )}`,
            )
            .then(() => {
              // not exist
              this.setState({ loading: false });
              this.setState({ error: mainError.response.data.error });
            })
            .catch(otherCheckError => {
              if (otherCheckError.response.status === 422) {
                // exist in other country DB
                axios
                  .post(`${OTHER_SERVER_URL}/api/login`, {
                    ...values,
                    source: 'therapy',
                  })
                  .then(otherResponse =>
                    // success in other country DB
                    this.redirect(
                      otherResponse,
                      OTHER_THERAPY_URL,
                      values.email,
                    ))
                  .catch(otherError => {
                    // exist in other country DB, wrong password
                    this.setState({ loading: false });
                    this.setState({ error: otherError.response.data.error });
                  });
              } else {
                // error in checking other country DB
                this.setState({ loading: false });
                this.setState({ error: mainError.response.data.error });
              }
            });
        } else {
          // exist in DB, wrong password
          this.setState({ loading: false });
          this.setState({ error: mainError.response.data.error });
        }
      });
  };

  renderBottom = () => {
    if (['esg'].includes(this.companyCode)) {
      return (
        <h4>
          <Link
            to={`/sign_up?company=${this.companyCode}`}
            style={{ color: '#2a5ccd' }}
          >
            {this.props.intl.formatMessage({
              id: 'eap.notAccountInformation',
              defaultMessage: "Don't have your account information?",
            })}
          </Link>
        </h4>
      );
    }

    return (
      <div>
        <p className="custom-auth-bottom-text">
          {this.props.intl.formatMessage({
            id: 'eap.dontHave',
            defaultMessage: "Don't have an account? ",
          })}{' '}
          <Link
            to={`/sign_up?company=${this.companyCode}`}
            style={{ color: '#2a5ccd' }}
          >
            {this.props.intl.formatMessage({
              id: 'eap.signUp',
              defaultMessage: 'Sign Up',
            })}
          </Link>
        </p>
        <p className="custom-auth-bottom-text">
          <Link to="/forgot_password" style={{ color: '#2a5ccd' }}>
            {this.props.intl.formatMessage({
              id: 'auth.forgotMyPassword',
              defaultMessage: 'Forgot My Password',
            })}
          </Link>
        </p>
      </div>
    );
  };

  render() {
    const { error, loading, visible } = this.state;
    const language = localStorage.getItem('selectedLanguage');
    return (
      <div className="custom-auth" role="main">
        <div className="auth-container custom-auth-container">
          <div className="custom-auth-text">
            <h1>
              {this.props.intl.formatMessage({
                id: 'eap.signin',
                defaultMessage: 'Sign In',
              })}
            </h1>
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="email">
                {this.props.intl.formatMessage({
                  id: 'eap.email',
                  defaultMessage: 'Email',
                })}
              </label>
              <input
                onChange={value => this.handleChange(value)}
                type="text"
                name="email"
                placeholder={
                  language === 'fr' ? 'Votre adresse de courriel' : 'Your Email'
                }
              />
              <label htmlFor="password">
                {this.props.intl.formatMessage({
                  id: 'eap.password',
                  defaultMessage: 'Password',
                })}
              </label>
              <div className="password-input">
                <div className="text-input">
                  <input
                    onChange={value => this.handleChange(value)}
                    type={visible ? 'text' : 'password'}
                    name="password"
                    placeholder={
                      language === 'fr' ? 'Votre mot de passe' : 'Your Password'
                    }
                  />
                </div>
                <EyeIcon isVisible={visible} onClick={() => this.setState({ visible: !visible })} />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 240,
                  marginTop: 40,
                }}
              >
                <SpinnerButton
                  label={this.props.intl.formatMessage({
                    id: 'eap.signin',
                    defaultMessage: 'Sign In',
                  })}
                  type="submit"
                  loading={loading}
                />
              </div>
            </form>
            {error && (
              <p
                className="error-text"
                aria-live='polite'
                role='alert'
              >
                {error}
              </p>
            )}
            {this.renderBottom()}
          </div>
          <img src={SessionTwo} alt="" />
          <div className="custom-auth-logo">
            <img src={InkblotLogo} alt="inkblot logo" />
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_SIGNIN}
          eventProperties={{ page: this.companyCode }}
        />
      </div>
    );
  }
}

SignIn.propTypes = {
  intl: PropTypes.object,
};

SignIn.defaultProps = {
  intl: {},
};

export default injectIntl(SignIn);
