import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import MetaTags from 'react-meta-tags';
import LanguageSelector from './LanguageSelector';
import CSALogo from '../../images/people-connect-total-health/csa.png';
import CSAFrLogo from '../../images/people-connect-total-health/csa-fr.jpg';
import { SERVER_URL } from '../../utils/environment';

import InkblotLogo from '../../images/people-connect-total-health/inkblot.png';
import LifeSpeakLogo from '../../images/people-connect-total-health/life-speak.png';
import WorkplaceOptions from '../../images/people-connect-total-health/work-place-options.png';
import AlspkEnLogo from '../../images/people-connect-total-health/alspk-en-logo.jpg';
import AlspkFrLogo from '../../images/people-connect-total-health/alspk-fr-logo.jpg';
import CalmAppLogo from '../../images/people-connect-total-health/calm-app-logo.png';
import MindBeaconLogo from '../../images/custom/logos/mind-beacon-logo.png';

const AlspkLogo = ({ lang }) => (
  <div>
    {lang === 'en' ? (
      <img src={AlspkEnLogo} alt="alavida" />
    ) : (
      <img src={AlspkFrLogo} alt="alavida-fr" />
    )}
  </div>
);
AlspkLogo.propTypes = {
  lang: PropTypes.string,
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { table: false };
  }

  toggleTable = () => {
    this.setState(prevState => ({ table: !prevState.table }));
  };

  render() {
    const lang = localStorage.getItem('selectedLanguage');
    return (
      <div className="csa">
        <MetaTags>
          <meta name="robots" content="noindex" />
        </MetaTags>
        <LanguageSelector />
        <div className="section-1">
          {lang === 'en' ? (
            <img className="people-logo" src={CSALogo} alt="people Logo" />
          ) : (
            <img className="people-logo" src={CSAFrLogo} alt="people Logo" />
          )}
          <FormattedHTMLMessage
            tagName="h2"
            id="csa.yourMental"
            defaultMessage="Your mental health resources"
          />
          <div className="divider" />
          <FormattedHTMLMessage
            tagName="p"
            id="csa.learnMore"
            defaultMessage="Learn more about your comprehensive benefits package."
          />
          <FormattedHTMLMessage tagName="h4" id="pch.yourProviders" />
          <div className="company-container">
            <div>
              <img src={InkblotLogo} alt="inkblot" />
            </div>
            <AlspkLogo lang={lang} />
            <div>
              <img src={CalmAppLogo} alt="calmapp" />
            </div>
            <div>
              <img src={MindBeaconLogo} alt="mindbeacon" />
            </div>
            <div>
              <img src={LifeSpeakLogo} alt="lifespeak" />
            </div>
            <div>
              <img src={WorkplaceOptions} alt="workplace options" />
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="container">
            <div className="logo">
              <div className="white-box">
                <img src={InkblotLogo} alt="" />
              </div>
            </div>
            <div className="content">
              <FormattedHTMLMessage
                tagName="h2"
                id="csa.talkTherapy"
                defaultMessage="Talk Therapy"
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.inkblotTitle"
                defaultMessage="Inkblot Therapy"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.inkblotTop"
                defaultMessage="Inkblot Therapy is Canada’s top provider of online video-based one-on-one or couples counselling. Care is provided by highly experienced professionals across Canada. Inkblot highly values long-term relationships with care providers. Your initial individual consultation is complimentary. Subsequent sessions are reimbursable through your paramedical health coverage or Health Spending Account (HSA). <br><br><b>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</b>"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.inkblotMostSuitable"
                defaultMessage="<span class='second-p'>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</span>"
              />
              <a
                className="pch-button"
                href="https://inkblottherapy.com/CSA"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedHTMLMessage tagName="span" id="csa.launchInkblot" />
              </a>
            </div>
          </div>
        </div>
        <div className="section-3">
          <div className="container">
            <div className="logo">
              <div className="white-box">
                <AlspkLogo lang={lang} />
              </div>
            </div>
            <div className="content">
              <h2>ALAViDA</h2>
              <FormattedHTMLMessage tagName="h2" id="csa.alavidaProgram" />
              <FormattedHTMLMessage tagName="p" id="csa.alavidaAccess" />
              <FormattedHTMLMessage tagName="h3" id="csa.whatIsAlavida" />
              <FormattedHTMLMessage tagName="p" id="csa.alavidaDescription" />
              <FormattedHTMLMessage tagName="h3" id="csa.whoIsAlavidaFor" />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.alavidaSecondaryDescription"
              />
              <FormattedHTMLMessage tagName="p" id="csa.learnMoreAlavida" />
              <FormattedHTMLMessage tagName="p" id="csa.alavidaDisclaimer" />
              <a
                className="pch-button"
                href={`https://try.alavida.co/CSA${lang === 'fr' ? '-fr' : ''}`}
                target="_blank"
              >
                <FormattedHTMLMessage
                  tagName="span"
                  id="csa.launchAlavida"
                  defaultMessage="Launch Alavida"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="section-4">
          <div className="container">
            <div className="logo">
              <div className="white-box">
                <img
                  src={MindBeaconLogo}
                  style={{
                    objectFit: 'contain',
                    width: '300px',
                    height: '150px',
                  }}
                  alt="beacon"
                />
              </div>
            </div>
            <div className="content">
              <FormattedHTMLMessage
                tagName="h2"
                id="csa.guidedDigitalTherapy"
                defaultMessage="Virtual Health"
              />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.beacon"
                defaultMessage="Beacon"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.beaconIsAvail"
                defaultMessage="Over 100 Canadians start MindBeacon’s Therapist Guided Program every single day. You are matched one-to-one with a licensed therapist who communicates with you via secure, private direct messages. Unlike talk therapy, there are no appointments to keep, instead you can send your therapist a message any time. MindBeacon provides Cognitive Behavioural Therapy (CBT), a proven form of treatment where your therapist will guide you through personalized exercises and readings customized over a 6-12 week program. It also works. Within the first two weeks of the program, 71% of people see a reduction in their symptoms related to stress, anxiety, burnout, depression, alcohol use, insomnia, trauma and more."
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.beaconMostSuitable"
                defaultMessage="<span class='second-p'>Most suitable for those seeking online counselling and a longer-term relationship with their counsellor.</span>"
              />
              <a
                className="pch-button"
                href={
                  lang === 'en'
                    ? 'https://www.mindbeacon.com/csa'
                    : 'https://www.mindbeacon.com/fr/csa'
                }
                target="_blank"
              >
                <FormattedHTMLMessage
                  tagName="span"
                  id="csa.launchBeacon"
                  defaultMessage="Launch MindBeacon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="view-compare">
          <FormattedHTMLMessage tagName="h2" id="csa.viewCompare" />
          <button
            type="button"
            className="view-button"
            onClick={this.toggleTable}
          >
            {this.state.table ? (
              <FormattedHTMLMessage tagName="span" id="csa.hideChart" />
            ) : (
              <FormattedHTMLMessage tagName="span" id="csa.viewChart" />
            )}
          </button>
        </div>
        {this.state.table && (
          <div className="compare-section">
            <div id="compare-table" className="compare-table">
              <div className="header">
                <p className="left-text"></p>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                </div>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                </div>
              </div>

              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.offers" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotOffers" />
                </div>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconOffers" />
                </div>
              </div>

              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.idealFor" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotIdealFor" />
                </div>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconIdealFor" />
                </div>
              </div>

              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.coverage" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotCoverage" />
                </div>
                <div className="block">
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconCoverage" />
                </div>
              </div>

              <div className="table-block">
                <p className="left-text">
                  <FormattedHTMLMessage tagName="p" id="csa.forMoreInfo" />
                </p>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.moreInfoInkblotLink"
                    defaultMessage="https://inkblottherapy.com/csa"
                  />
                </div>
                <div className="block">
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.moreInfoMBeaconLink"
                    defaultMessage="www.mindbeacon.com/csa"
                  />
                </div>
              </div>
            </div>

            {/* mobile table starts here */}
            <div className="compare-table-mobile">
              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.offers" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotOffers" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconOffers" />
                </div>
              </div>

              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.idealFor" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotIdealFor" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconIdealFor" />
                </div>
              </div>

              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.coverage" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.inkblotCoverage" />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage tagName="p" id="csa.mBeaconCoverage" />
                </div>
              </div>

              <div className="block">
                <p className="title">
                  <FormattedHTMLMessage tagName="span" id="csa.forMoreInfo" />
                </p>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.inkblotTitle"
                    defaultMessage="Inkblot Therapy"
                  />
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.moreInfoInkblotLink"
                    defaultMessage="https://inkblottherapy.com/csa"
                  />
                </div>
                <div className="table-block-mobile">
                  <FormattedHTMLMessage
                    tagName="h2"
                    id="csa.mBeaconTitle"
                    defaultMessage="MindBeacon"
                  />
                  <FormattedHTMLMessage
                    tagName="p"
                    id="csa.moreInfoMBeaconLink"
                    defaultMessage="www.mindbeacon.com/csa"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="section-5">
          <div className="container">
            <div className="logo">
              <div className="white-box">
                <img src={CalmAppLogo} alt="CalmAppLogo" />
              </div>
            </div>
            <div className="content">
              <FormattedHTMLMessage tagName="h2" id="csa.wellBeingPlatform" />
              <FormattedHTMLMessage tagName="h1" id="csa.calmHeader" />
              <FormattedHTMLMessage tagName="p" id="csa.calmCopy" />
              <a
                className="pch-button"
                href={`${SERVER_URL}/production/CSAGroupCalmLaunchFlyer${
                  lang === 'fr' ? 'FR' : 'EN'
                }.pdf`}
                target="_blank"
              >
                <FormattedHTMLMessage tagName="span" id="csa.calmCTA" />
              </a>
            </div>
          </div>
        </div>
        <div className="section-6">
          <div className="container" style={{ flexDirection: 'row-reverse' }}>
            <div className="logo">
              <div className="white-box">
                <img src={LifeSpeakLogo} alt="lifespeak" />
              </div>
            </div>
            <div className="content">
              <FormattedHTMLMessage tagName="h2" id="csa.wellBeingPlatform" />
              <FormattedHTMLMessage
                tagName="h1"
                id="csa.lifeSpeakTitle"
                defaultMessage="LifeSpeak"
              />
              <FormattedHTMLMessage tagName="p" id="csa.lifeSpeakIs" />
              <a
                className="pch-button"
                href={
                  lang === 'fr'
                    ? 'https://csagroup.lifespeak.com/login-questionnaire'
                    : 'https://csagroup.lifespeak.com/?sharekey=1fe9a185-10d6-40c2-92fb-0cf6452ec34f&lang=EN'
                }
                target="_blank"
              >
                <FormattedHTMLMessage
                  tagName="span"
                  id="csa.accessOnline"
                  defaultMessage="View Details"
                />
              </a>
              <FormattedHTMLMessage
                tagName="p"
                id="csa.lifeSpeakId"
                defaultMessage="id = lifespeak"
              />
              <FormattedHTMLMessage
                tagName="p"
                id="csa.lifeSpeakDownload"
                defaultMessage="Or download the app in the Google Play or App Store using the following credentials:<br>
		  <b>Client Name</b>: csagroup <br>
		  <b>Client Password</b>: lifespeak"
              />
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="container">
            <div className="logo">
              <div className="white-box">
                <img src={WorkplaceOptions} alt="workplace options" />
              </div>
            </div>
            <div className="content">
              <FormattedHTMLMessage tagName="h2" id="csa.workplaceOptions" />
              <FormattedHTMLMessage tagName="p" id="csa.employeeSupportProg" />
              <a
                className="pch-button"
                href="http://global.resourcesforyourlife.com"
                target="_blank"
              >
                <FormattedHTMLMessage tagName="span" id="csa.launchWorkplace" />
              </a>
              <FormattedHTMLMessage tagName="p" id="csa.companyCode" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
