import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import get from 'lodash/get';
import queryString from 'query-string';

import { validEmail } from 'src/utils/form';
import Consent from '../auth/consent';
import { SERVER_URL } from '../../utils/environment';
import { redirectToTherapy } from '../../utils/redirect';
import { minSignupData } from '../../pagePartials/auth';
import LogEventOnMount from '../LogEventOnMount';
import {
  OPEN_FORGOT_PASSWORD,
  logEvent,
  COMPLETED_SIGNUP,
} from '../../utils/amplitude';
import EyeIcon from '../EyeIcon';

class Dependants extends Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search);

    this.state = {
      signUpDisabled: false,
      errorMessage: '',
      companyCode: (params.company || '').toLowerCase(),
      childCompanyId: params?.companyId || '',
      error: {},
      visiblePassword: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recentlyUpdated !== prevState.recentlyUpdated) {
      this.validateForm(this.state.recentlyUpdated);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      errorMessage: '',
      recentlyUpdated: name,
    });
  };

  handleChangeCheckbox = event => {
    this.setState({
      privacyConsent: event.target.checked,
      recentlyUpdated: event.target.name,
    });
  };

  handleSubmit = event => {
    const { signUpDisabled } = this.state;
    event.preventDefault();

    if (!signUpDisabled) this.signUp();
  };

  emailExists = email => {
    axios
      .get(
        `${SERVER_URL}/api/signup/check_email?email=${encodeURIComponent(
          email,
        )}`,
      )
      .then(() => {})
      .catch(e => {
        const isFr = localStorage.getItem('selectedLanguage') === 'fr';
        this.setState({
          error: {
            ...this.state.error,
            email: isFr ? 'Ce courriel existe déjà' : e.response.data.error,
          },
        });
      });
  };

  signUp = () => {
    const { companyCode, privacyConsent, childCompanyId } = this.state;
    const values = _.omit(this.state, [
      'showForm',
      'message',
      'signUpDisabled',
      'error',
      'signUpError',
      'recentlyUpdated',
      'privacyConsent',
    ]);
    const mergedValues = {
      ...values,
      source: 'therapy',
      consent_yn: privacyConsent,
      child_company_id: childCompanyId,
      corporate_account: companyCode,
    };
    axios
      .post(`${SERVER_URL}/api/v2/eap_dependants`, mergedValues)
      .then(response => {
        const token = response.data.access_token;
        redirectToTherapy(token);
        logEvent(COMPLETED_SIGNUP, {
          page: companyCode,
          flow_type: 'b2b registration',
          b2b_page_type: 'custom portal',
          dependant: 'true',
          company: companyCode,
        });
      })
      .catch(e => {
        this.setState({ errorMessage: get(e, 'response.data.message', '') });
      });
  };

  validateForm = recentlyUpdated => {
    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
    const requiredField = isFr
      ? 'Ceci est un champ obligatoire'
      : 'This is a required field';
    const emailValid = isFr
      ? 'Veuillez saisir une adresse courriel - Exemple, utilisateur@exemple.com'
      : 'Please enter an email address - Example, user@example.com';
    const passwordSix = isFr
      ? 'Le mot de passe doit être au moins de 8 caractères '
      : 'Password must be at least 8 characters';
    const passwordMatch = isFr
      ? 'Les mots de passe doivent correspondre'
      : 'Passwords must match';

    const {
      first_name,
      last_name,
      email,
      password,
      privacyConsent,
      password_confirm,
    } = this.state;
    const error = {};

    if (first_name === '') {
      error.first_name = requiredField;
    }
    if (last_name === '') {
      error.last_name = requiredField;
    }
    if (!privacyConsent) {
      error.privacyConsent = requiredField;
    }
    if (email) {
      if (validEmail.test(email)) {
        // to reduce memory usage, we will call check_email api only when email field updated
        if (recentlyUpdated === 'email') {
          this.emailExists(email);
        }
      } else {
        error.email = emailValid;
      }
    }
    if (password) {
      if (password.length < 8) {
        error.password = passwordSix;
      } else if (password !== password_confirm) {
        error.password_confirm = passwordMatch;
      }
    }

    this.setState({
      recentlyUpdated: null,
      signUpDisabled:
        !_.isEmpty(error) ||
        !first_name ||
        !last_name ||
        !privacyConsent ||
        !email ||
        !password ||
        !password_confirm,
      error,
    });
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;

    return (
      <div>
        <p className="error-text">{errorMessage}</p>
      </div>
    );
  };

  renderInputs = inputs => {
    const { error, visiblePassword } = this.state;
    return _.map(inputs, ({ id, name, type, fullWidth, text, defaultText }) => (
      <div key={id}>
        <h4>
          {this.props.intl.formatMessage({
            id: text,
            defaultMessage: defaultText,
          })}
        </h4>
        {type === 'password' ? (
          <div className="password-input">
            <div className="text-input">
              <input
                id={name}
                className={fullWidth ? 'full-width' : ''}
                onChange={event => this.handleChange(event)}
                type={visiblePassword[name] ? 'text' : 'password'}
                name={name}
                placeholder={this.props.intl.formatMessage({
                  id: text,
                  defaultMessage: defaultText,
                })}
                value={this.state[name]}
              />
            </div>
            <EyeIcon
              isVisible={visiblePassword[name]}
              onClick={() => {
                const visible = { ...visiblePassword };
                visible[name] = !visiblePassword[name];
                this.setState({ visiblePassword: { ...visible } });
              }}
            />
          </div>
        ) : (
          <input
            id={name}
            className={fullWidth ? 'full-width' : ''}
            onChange={event => this.handleChange(event)}
            type={type}
            name={name}
            placeholder={
              type === 'email'
                ? 'example@example.com'
                : this.props.intl.formatMessage({
                    id: text,
                    defaultMessage: defaultText,
                  })
            }
            value={this.state[name]}
          />
        )}
        <p
          className={`error-text ${error[name] ? '' : 'hidden'}`}
          style={{ fontSize: '11px', position: 'absolute' }}
        >
          {error[name]}
        </p>
      </div>
    ));
  };

  renderConsent = () => {
    const { error } = this.state;
    return (
      <Consent
        error={error}
        handleChangeCheckbox={this.handleChangeCheckbox}
        company={this.state.companyCode}
      />
    );
  };

  render() {
    const { companyCode } = this.state;

    return (
      <div className="custom-auth custom-dependants" role="main">
        <div className="dependants-container custom-auth-container">
          <div className="dependant-auth-form">
            <h2>
              {this.props.intl.formatMessage({
                id: 'eap.dependantAccountCreate',
                defaultMessage: 'Dependant Account Creation',
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {this.renderInputs(minSignupData)}
              <div>
                <h4 className="label">
                  {this.props.intl.formatMessage({
                    id: 'eap.relationship',
                    defaultMessage: 'Relationship',
                  })}
                </h4>
                <select
                  className="full-width referral-dropdown"
                  required
                  onChange={value => this.handleChange(value)}
                  type="text"
                  name="relationship"
                  defaultValue=""
                  onInvalid={e => {
                    const isFr = localStorage.getItem('selectedLanguage') === 'fr';
                    e.currentTarget.setCustomValidity(
                      isFr
                        ? 'Veuillez sélectionner un item dans la liste'
                        : 'Please select an item in the list',
                    );
                  }}
                  onInput={e => {
                    e.currentTarget.setCustomValidity('');
                  }}
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: 'eap.pleaseSelect',
                      defaultMessage: 'Please select an option.',
                    })}
                  </option>
                  <option value="spouse">
                    {this.props.intl.formatMessage({
                      id: 'eap.spouse',
                      defaultMessage: 'Spouse/Partner',
                    })}
                  </option>
                  <option value="dependant">
                    {this.props.intl.formatMessage({
                      id: 'eap.dependant',
                      defaultMessage: 'Dependant',
                    })}
                  </option>
                </select>
              </div>
              {this.renderConsent()}
              <div>
                <button type="submit">
                  {this.props.intl.formatMessage({
                    id: 'eap.signUp',
                    defaultMessage: 'Sign Up',
                  })}
                </button>
              </div>
            </form>
            {this.renderErrorMessage()}
            <h4>
              {companyCode === 'pas' || companyCode ===  'arta' || companyCode ===  'enmax' || companyCode === 'cima'?
                this.props.intl.formatMessage({
                  id: 'eap.areYouMember',
                  defaultMessage: 'Are you a member?'
                }) :
                this.props.intl.formatMessage({
                  id: 'eap.areYouEmployee',
                  defaultMessage: 'Are you an employee?',
                })
              }{' '}
              <Link
                to={`/sign_up?company=${companyCode}`}
                style={{ color: '#2a5ccd' }}
              >
                {this.props.intl.formatMessage({
                  id: 'eap.getStarted',
                  defaultMessage: 'Get Started',
                })}
              </Link>
            </h4>
          </div>
        </div>
        <LogEventOnMount
          eventType={OPEN_FORGOT_PASSWORD}
          eventProperties={{
            page: companyCode,
            flow_type: 'b2b registration',
            b2b_page_type: 'custom portal',
            dependant: 'true',
            company: companyCode,
          }}
        />
      </div>
    );
  }
}

Dependants.propTypes = {
  intl: PropTypes.object,
};

Dependants.defaultProps = {
  intl: {},
};

export default injectIntl(Dependants);
