/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import inkblotLogoDark from '../../images/custom/logos/inkblot-logo-dark-blue.svg';
import { whichCompany } from '../../utils/pageUtils';
import { COUNTRY } from '../../utils/environment';

const InkblotLogo = ({ moveHigher }) => (
  <div className={`logo inkblot ${moveHigher ? 'higher-position' : ''}`}>
    <img src={inkblotLogoDark} alt="inkblot logo" id="customLogo" />
  </div>
);
const Complimentary = ({ intl, minutes, title }) => {
  return (
    <p className="bold">
      {intl.locale === 'fr'
        ? 'Votre première consultation individuelle est gratuite'
        : 'Your initial individual consultation is complimentary'}
      {minutes !== null
        ? intl.locale === 'fr'
          ? `, ainsi que toutes les heures de consultation supplémentaires payées par ${title}.`
          : `, plus any additional counseling hours sponsored by ${title}.`
        : '.'}
    </p>
  );
};
const SubsequentParagraph = ({
  intl,
  subsequentSessionsFr,
  subsequentSessionsUS,
  subsequentSessions,
}) => {
  const Message = ({ defaultMsg }) => (
    <FormattedHTMLMessage
      id="eap.subsequentSessions"
      defaultMessage={defaultMsg}
    />
  );
  const isUS = COUNTRY === 'US' && subsequentSessionsUS;
  const defaultMsg = isUS
    ? subsequentSessionsUS
    : intl.locale === 'fr' && subsequentSessionsFr
      ? subsequentSessionsFr
      : subsequentSessions;
  return (
    <p>
      <Message defaultMsg={defaultMsg} />
    </p>
  );
};

const ParagraphsEFAPCompanies = ({
  intl,
  efapSecond,
  efapSecondFr,
  efapThird,
  efapThirdFr,
  finalParagraph,
  finalParagraphFr,
}) => {
  return (
    <div className={'custom-efap-collab'}>
      {efapSecond && <p>{intl.locale === 'fr' ? efapSecondFr : efapSecond}</p>}
      <p className="bold">
        {
          whichCompany().path === 'inconfidence' ?
            <FormattedHTMLMessage {...{
              id: 'eap.servicesAvailableInConfidence',
              defaultMessage:
                'Inkblot’s services are voluntary and immediately available, 24/7/365.',
            }} /> :
            whichCompany().path === 'inconfidencepersonal' ?
              <FormattedHTMLMessage {...{
                id: 'eap.servicesAvailableInConfidencePersonal',
                defaultMessage:
                  'Inkblot’s services are voluntary and immediately available, 24/7/365.',
              }} /> :
              <FormattedHTMLMessage {...{
                id: 'eap.servicesAvailable',
                defaultMessage:
                  'Inkblot’s services are voluntary and immediately available, 24/7/365.',
              }} />
        }
      </p>
      {efapThird && <p>{intl.locale === 'fr' ? efapThirdFr : efapThird}</p>}
      {finalParagraph && (
        <FinalParagraph {...{ intl, finalParagraphFr, finalParagraph }} />
      )}
    </div>
  );
};
const Logo = ({ intl, title, logo, frLogo }) => {
  return (
    <img
      src={intl.locale === 'fr' && frLogo ? frLogo : logo}
      alt={`${title} logo`}
      id="customLogo"
    />
  );
};

const FinalParagraph = ({ intl, finalParagraphFr, finalParagraph }) => {
  const Message = ({ defaultMsg }) => (
    <FormattedHTMLMessage id="eap.finalParagraph" defaultMessage={defaultMsg} />
  );
  const defaultMsg =
    intl.locale === 'fr' && finalParagraphFr
      ? finalParagraphFr
      : finalParagraph;
  return (
    <p>
      <Message defaultMsg={defaultMsg} />
    </p>
  );
};
function isSingleParagraphLong(reactEl) {
  const str = ReactDOMServer.renderToString(reactEl);
  return str && str.length > 450;
}
const SectionTwo = props => {
  const {
    logo,
    secondLogo,
    title,
    EFAP,
    EAP,
    minutes,
    intl,
    singleParagraph,
    singleParagraphFr,
    subsequentSessions,
    subsequentSessionsFr,
    subsequentSessionsUS,
    frLogo,
    frSecondLogo,
    frTitle,
    removeSecondParagraph,
    finalParagraph,
    heures,
    efapSecond,
    efapSecondFr,
    efapThird,
    efapThirdFr,
    finalParagraphFr,
  } = props;
  const isNoneEFAP = !EFAP && !EAP && !singleParagraph;
  const isEFAP = EFAP || EAP;
  const renderEapPartneredCopy = () => {
    const company = whichCompany().path;
    const companyTitle = intl.locale === 'fr' && frTitle ? frTitle : title
    if (company === 'eiqc') {
      return intl.formatMessage({
        id: 'eap.eiqc',
        defaultMessage:
          'A total of 5 hours of individual counseling and 5 hours of couple counseling are included in your insurance plan with GSC. For the first five (5) hours of counseling, you will be able to choose the psychological services of your choice from the available provider types (social worker, psychotherapist and psychologist). However, for subsequent sessions, only the services of an Inkblot psychologist will be covered under the regular plan offered by Green Shield Canada (GSC).',
      })
    }
    if (company === 'asebp') {
      return companyTitle + ' ' + intl.formatMessage({
        id: 'eap.asebp.partnered',
        defaultMessage: 'ASEBP has partnered with Inkblot to provide you with easy to access support for your life, work and mental health challenges – any time – any place.',
      })
    }
    return companyTitle + ' ' +
      intl.formatMessage(
        {
          id: 'eap.partnered',
          defaultMessage:
            'has partnered with Inkblot to provide you with easy to access support for your {typesOfChallengesEN} challenges - any time, any place.',
        },
        {
          typesOfChallengesEN:
            company === 'arta'
              ? 'life and mental health'
              : 'life, work and mental health',
          typesOfChallengesFR:
            company === 'arta'
              ? 'de vie et de santé mentale'
              : 'de vie, de travail et de santé mentale'
        },
      );
  };
  return (
    <div className="section-2">
      <div className="section-2-wrapper">
        <div className="logo-content-row">
          <div
            className={`logo b2b ${secondLogo ? 'two-custom-logos' : ''} ${isSingleParagraphLong(singleParagraph) ? 'higher-position' : ''
              }`}
          >
            {logo && (
              <Logo intl={intl} title={title} logo={logo} frLogo={frLogo} />
            )}
            {secondLogo && (
              <Logo
                intl={intl}
                title={title}
                logo={secondLogo}
                frLogo={frSecondLogo}
              />
            )}
          </div>
          <div className="content b2b">
            {/* singleParagraph is true and this is the only paragraph in view */}
            {singleParagraph && (
              <div className="custom-inkblot-collab">
                <p>
                  {intl.locale === 'fr' ? singleParagraphFr : singleParagraph}
                </p>
              </div>
            )}

            {/* First paragraph for non EFAP companies */}
            {isNoneEFAP && (
              <div className="custom-inkblot-collab">
                <p>
                  {whichCompany().yourMentalHealth &&
                    (intl.locale === 'fr'
                      ? whichCompany().yourMentalHealthFr
                      : whichCompany().yourMentalHealth)}
                  {!whichCompany().yourMentalHealth && (
                    <FormattedHTMLMessage
                      tagName="p"
                      id="eap.partneredNonEAP"
                      values={{
                        frTitle: frTitle || title,
                        title,
                      }}
                    />
                  )}
                </p>
                {whichCompany().path === 'brfn' &&
                  <p>Counselling is sponsored by Enbridge</p>
                }
              </div>
            )}
            {/* common paragraph for EAP */}
            {isEFAP && !singleParagraph && (
              <div className="custom-efap-collab">
                <p className="italic-span">
                  {renderEapPartneredCopy()}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="logo-content-row" style={{ marginTop: '20px' }}>
          <InkblotLogo moveHigher={isSingleParagraphLong(singleParagraph)} />
          <div className="inkblot content">
            {/* Two paragraphs for EFAP companies */}
            {isEFAP && (
              <ParagraphsEFAPCompanies
                intl={intl}
                efapSecond={efapSecond}
                efapSecondFr={efapSecondFr}
                efapThird={efapThird}
                efapThirdFr={efapThirdFr}
                finalParagraph={finalParagraph}
                finalParagraphFr={finalParagraphFr}
              />
            )}

            {/* For non EFAP companies */}
            {isNoneEFAP && (
              <div className="custom-inkblot-collab">
                {/* Second paragraph */}
                {!removeSecondParagraph && (
                  <Complimentary intl={intl} minutes={minutes} title={title} />
                )}

                {/* Third paragraph */}
                <SubsequentParagraph
                  intl={intl}
                  {...{
                    subsequentSessionsFr,
                    subsequentSessionsUS,
                    subsequentSessions,
                  }}
                />

                {finalParagraph && (
                  <FinalParagraph
                    {...{ intl, finalParagraphFr, finalParagraph }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionTwo.propTypes = {
  logo: PropTypes.string,
  secondLogo: PropTypes.string,
  title: PropTypes.string.isRequired,
  EFAP: PropTypes.bool,
  EAP: PropTypes.bool,
  minutes: PropTypes.string,
  intl: intlShape.isRequired,
  singleParagraph: PropTypes.string,
  singleParagraphFr: PropTypes.string,
  subsequentSessions: PropTypes.string,
  subsequentSessionsFr: PropTypes.string,
  subsequentSessionsUS: PropTypes.string,
  frLogo: PropTypes.string,
  frSecondLogo: PropTypes.string,
  frTitle: PropTypes.string,
  removeSecondParagraph: PropTypes.bool,
  finalParagraph: PropTypes.string,
  finalParagraphFr: PropTypes.string,
  heures: PropTypes.string,
  efapSecond: PropTypes.string,
  efapSecondFr: PropTypes.string,
  efapThird: PropTypes.string,
  efapThirdFr: PropTypes.string,
};

SectionTwo.defaultProps = {
  minutes: null,
  EFAP: false,
  EAP: false,
  subsequentSessionsUS:
    'Subsequent sessions cost just $110/hour which may be covered by your private health insurance, benefits coverage or paid privately.',
  subsequentSessions:
    'Subsequent sessions cost just $90/hour which may be covered by your private health insurance, benefits coverage or paid privately.',
  subsequentSessionsFr:
    'Les séances subséquentes ne coûtent que 90 $ l’heure. Elles peuvent être couvertes par votre assurance maladie privée, un régime de garanties collectives ou un régime privé.',
  removeSecondParagraph: false,
  finalParagraph: null,
  finalParagraphFr: null,
  heures: null,
  singleParagraph: null,
  singleParagraphFr: null,
  efapSecond: null,
  efapSecondFr: null,
  efapThird: null,
  efapThirdFr: null,
};

export default injectIntl(SectionTwo);
